:root {
    --black: #000;
    --light-text: #000;
    --light-text-accent: #000;
    --light-bg: #fff;
    --dark-text: #fff;
    --dark-bg: #000;
    --medium-text: #000;
    --medium-bg: #cfd8dc;
    --accent: #f7ff14;
    --accent-text: #000;
    --accent-text-accent: #f7ff14;
    --accent-bg: #f7ff14;
    --accentSecondary: #cfd8dc;
    --accentSecondary-text: #000;
    --accentSecondary-bg: #cfd8dc;
    --accentWarn: #ff0200;
    --accentWarn-text: #ffffff;
    --accentWarn-bg: #ff0200;
    --accentOk: #4caf50; /* @TODO: Update color here */
    --accentOk-text: #fff;
    --accentOk-bg: #4caf50; /* @TODO: Update color here */
    --grey-100: #f3f3f3;
    --grey-200: #dedede;
    --grey-300: #cac6c5;
    --grey-400: #888888;
    --grey-500: #888888; /* do not use */
    --grey-600: #888888; /* do not use */
    --shadow-sm: 0px 4px 4px rgba(0, 0, 0, 0.25);
    --shadow-md: 0px 15px 80px rgba(0, 0, 0, 0.04), 0px 5px 24px rgba(0, 0, 0, 0.05), 0px 2px 10px rgba(0, 0, 0, 0.03),
        0px 1px 4px rgba(0, 0, 0, 0.02);
    --shadow-lg: 0px 15px 80px rgba(0, 0, 0, 0.04), 0px 5px 24px rgba(0, 0, 0, 0.05), 0px 2px 10px rgba(0, 0, 0, 0.03),
        0px 1px 4px rgba(0, 0, 0, 0.02);
    --font-primary: 'OpelNext', ui-sans-serif, system-ui, -apple-system, sans-serif;
}

html {
    font-family: var(--font-primary);
}
