.bg-next {
    background-image: url(assets/icons/next-black.svg);
}

.bg-tikBlack {
    background-image: url(assets/icons/tik-black.svg);
}

html {
    font-family: var(--font-primary);
    font-size: 16px; // 1rem = 16px
    scroll-behavior: smooth;
    overflow-x: hidden;
}
