@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
@import '../../../root.css';
@import '../../../shared-stylings.scss';

.carousel {
    &__indicator-button {
        @apply bg-light-bg w-17 rounded-3 h-5 opacity-20 #{!important};
    }

    &__indicator-button--active {
        @apply bg-accent w-26 h-6 opacity-100 #{!important};
    }

    &__indicator-infinite-button {
        @apply bg-light-bg rounded-3 h-4 w-14 opacity-20 #{!important};
    }
}

wholesale-m060-c060-vehicle-carousel {
    .carousel__indicator-button {
        @apply opacity-100 #{!important};
    }
}

.opel-link {
    display: inline-flex;
    position: relative;
    overflow: hidden;

    span {
        position: relative;
        z-index: 2;
        -webkit-transition:
            color 0.3s,
            border-color 0.3s;
        transition:
            color 0.3s,
            border-color 0.3s;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        z-index: 1;
        width: 136%;
        height: 100%;
        background-color: var(--accent);
        -webkit-transform-origin: 200% 0;
        transform-origin: 200% 0;
        left: 0;
        -webkit-transform: skew(-23deg) translateX(-101%);
        transform: skew(-23deg) translateX(-101%);
        -webkit-transition: -webkit-transform 0.55s cubic-bezier(0, 0.545, 0.19, 1);
        transition:
            transform 0.55s cubic-bezier(0, 0.545, 0.19, 1),
            -webkit-transform 0.55s cubic-bezier(0, 0.545, 0.19, 1);
    }

    &:hover {
        span {
            color: var(--light-text);
            -webkit-transition:
                color 0.3s,
                border-color 0.3s;
            transition:
                color 0.3s,
                border-color 0.3s;
        }

        svg {
            fill: var(--light-text);
        }

        &::after {
            -webkit-transform: skew(-23deg) translateX(0);
            transform: skew(-23deg) translateX(0);
            -webkit-transition: -webkit-transform 0.55s cubic-bezier(0, 0.545, 0.19, 1);
            transition: -webkit-transform 0.55s cubic-bezier(0, 0.545, 0.19, 1);
        }
    }
}

.m060c050 {
    .sales-check__price-main .price-value,
    .sales-check__price-main .price-currency {
        @apply text-14 leading-20 text-grey-400 font-normal;
    }
    .carousel {
        .carousel__item--unactive {
            @apply opacity-50 transition-opacity;
        }

        .carousel__indicator {
            @apply flex flex-nowrap gap-x-8 gap-y-0 px-60 pb-32 pt-16;
            .carousel__indicator-button {
                @apply max-w-24 bg-grey-400 mx-0 h-2 w-auto cursor-default rounded-none #{!important};
                &--active {
                    @apply bg-black #{!important};
                }
            }
        }
    }
}
